<template>
  <v-app>

    <AppToolbar />

    <v-main class="bgDefault" >
        <router-view></router-view>
    </v-main>

    <Footer/>

  </v-app>
</template>

<script>
import AppToolbar from "@/components/appToolbar/AppToolbarOld.vue";
import Footer from "@/components/footer/Footer.vue";
import { useTheme } from 'vuetify'

export default {
  components: { AppToolbar,Footer  },
  setup () {
    const theme = useTheme()
    const themeMode = theme.global.name.value;
    return {
      themeMode,
      theme,
    }
  },
  async mounted() {
    try {
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search);
      let temaURL = params.get('theme');
      temaURL === "myCustomLightTheme" ?  this.theme.global.name.value = 'myCustomLightTheme' :  this.theme.global.name.value = 'myCustomDarkTheme'
    }catch (e){
      window.matchMedia('(prefers-color-scheme: dark)').matches ?  this.theme.global.name.value = 'myCustomDarkTheme' : this.theme.global.name.value = 'myCustomLightTheme'
    }

  }
};
</script>

<style lang="scss">
.bgDefault {
  background-image: url('~@/assets/imgs/backGroundDetail 2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
