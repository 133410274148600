import {createRouter, createWebHistory,createWebHashHistory} from "vue-router";

const routes = [
    {
        path: "/",
        name: "inicio",
        component: () => import("../views/HomeView.vue"),
    },
    {
        path: "/sobre",
        name: "sobre",

        component: () => import("../views/AboutView.vue"),
    },
    {
        path: "/produtos",
        name: "produtos",

        component: () => import("../views/ProductsView.vue"),
    },
    {
        path: "/parceiros",
        name: "parceiros",

        component: () => import("../views/PartnersView.vue"),
    },
    {
        path: "/contato",
        name: "contato",

        component: () => import("../views/TalkWithUsView.vue"),
    },
    {
        path: "/status",
        name: "status",

        component: () => import("../views/StatusSIPPAGClientsOriginal.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        component: () => import("../views/HomeView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {el: to.hash, top: 100, behavior: "smooth"};
        } else {
            return {top: 0, behavior: "smooth"};
        }
    },
});

export default router;
