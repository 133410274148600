import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import Vue3Autocounter from 'vue3-autocounter';
import Toast from "vue-toastification";

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const myCustomDarkTheme = {
    dark: true,
    colors: {
        card: '#212121',
        icon: '#ffffff',
        'secondary-card': '#202323',
        primary: '#6200EE',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#ffffff',
        success: '#4CAF50',
        warning: '#FB8C00',
    }
}

const myCustomLightTheme = {
    dark: false,
    colors: {
        background: '#ffffff',
        icon: '#34659b',

        surface: '#34659b',
        card: '#ffffff',
        'secondary-card': '#3f72a9',
        primary: '#6200EE',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#34659b',
        success: '#4CAF50',
        warning: '#FB8C00',
    }
}

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
            myCustomLightTheme,
            myCustomDarkTheme
        }
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
    },
})

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const options = {
    // You can set your default options here
};

createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(store).use(router)
    .use(Toast, options)
    .use(vuetify)
    .component('vue3-autocounter', Vue3Autocounter)
    .mount('#app')
