<template>
  <v-card class="rounded-0" flat>

    <v-card-title class="d-flex flex-row justify-center ">
      <v-spacer></v-spacer>
      <a href="/"><img src="../../assets/LOGO-dark-mode.svg" alt="Logo GOAT Soluções Digitais" width="131" height="58"/></a>
      <v-spacer></v-spacer>
      <div class="d-flex justify-center align-center">
        <ThemeButton class="theme-toggle" />
      </div>
    </v-card-title>

    <div class=" d-flex flex-row justify-center align-center">
      <v-tabs>
        <v-tab to="/">Inicio</v-tab>
        <v-tab to="/sobre">Quem Somos</v-tab>
        <v-tab to="/produtos">Produtos</v-tab>
        <v-tab to="/parceiros">Parceiros</v-tab>
        <v-tab to="/contato">Fale Conosco</v-tab>
        <v-tab to="/status">Status</v-tab>
      </v-tabs>
    </div>

  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import ThemeButton from "@/components/themeButton/ThemeButton.vue";
export default defineComponent({
  components: {ThemeButton}
});
</script>
<style lang="scss">

</style>
