import { createStore } from 'vuex'

export default createStore({
  state: {
    darkMode: localStorage.getItem("user-theme") === "dark-theme"
  },

  mutations: {
    SET_THEME(state,isDark:boolean){
      state.darkMode = isDark
      if(isDark){
        localStorage.setItem("user-theme", "dark-theme");
      }
      else{
        localStorage.setItem("user-theme", "light-theme")
      }

    }
  },
  actions: {
  },
  getters: {
  },
  modules: {
  }
})
