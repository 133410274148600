<template>
  <div :class=" this.$vuetify.theme.name === 'myCustomDarkTheme' ? 'changeThemeDark' : 'changeThemeLight'" >
    <v-icon icon="mdi-theme-light-dark" @click="toggleTheme" color="iconBg" ></v-icon>
  </div>
</template>

<script>
// import { mapState, mapMutations } from "vuex";
import { useTheme } from 'vuetify'

export default {
  setup () {
    const theme = useTheme()
    const themeMode = theme.global.name.value;
    return {
      themeMode,
      theme,
      toggleTheme: () =>{
        theme.global.name.value = theme.global.current.value.dark ? 'myCustomLightTheme' : 'myCustomDarkTheme'
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        params.set('theme', theme.global.name.value);
        url.search = params.toString();
        window.history.replaceState({}, '', url.toString());
      }
    }
  }
}


</script>

<style scoped>
.changeThemeDark {
  transition:.5s ease-in-out;
  transform: rotate(44.5deg);
}

.changeThemeLight {
  transition:.5s ease-in-out;
  transform: rotate(224deg);
}

</style>
