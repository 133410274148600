<template>
  <v-footer>
    <v-row justify="center" no-gutters>

      <v-card class="d-flex flex-column align-center bg-transparent text-white" max-width="450" flat>
        <a href="/"><img src="../../assets/LOGO-dark-mode.svg" alt="Logo GOAT Soluções Digitais"/></a>
        <div class="mt-1 text-center">Desenvolvimento de soluções na área de TI para órgãos públicos com a qualidade que você precisa pelo preço que você merece.</div>
        <div class=" text-center"> Av. Antônio Sales, 1885, sala 901 - 903 Dionísio Torres, Fortaleza - Ceará </div>
      </v-card>

      <v-col class="text-center text-md-subtitle-2" cols="12">

        <div class="d-flex flex-wrap justify-center text-md-subtitle-2 pb-3 pt-3">
            <router-link to="/" class="text-white mr-4" style="text-decoration: none">Inicio</router-link>
            <router-link to="/sobre" class="text-white mr-4" style="text-decoration: none">Quem Somos</router-link>
            <router-link to="/produtos" class="text-white mr-4" style="text-decoration: none">Produtos</router-link>
            <router-link to="/parceiros" class="text-white mr-4" style="text-decoration: none">Parceiros</router-link>
            <router-link to="/contato" class="text-white" style="text-decoration: none">Fale Conosco</router-link>
        </div>

        <v-divider></v-divider>

        <div class="pt-2"> © 2015 - {{new Date().getFullYear()}} GOAT Soluções Digitais - Todos direitos reservados </div>

      </v-col>

    </v-row>
  </v-footer>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",

  data() {
    return {
      theme: localStorage.getItem("user-theme"),
    };
  },
});
</script>

